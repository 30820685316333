<template>
    <div>
        <ValidationObserver :ref="'observer_' + form.id" v-slot="{ invalid, validated, passes, validate }">
            <v-col cols="12" class="pa-0" style="margin-top: 15px">
                <v-card class="side-block" >
                    <div>
                        <div class="d-flex">
                            <div class="pa-4 participants mr-auto">
                                <v-icon v-if="edit" color="primary" size="15" class="mb-1" :title="$t('delete')" @click="deleteForm">mdi-delete-outline</v-icon>
                                <v-icon v-if="edit" color="primary" size="15" class="ml-1 mb-1" :title="$t('edit')" @click="editGroup">mdi-file-document-edit-outline</v-icon>
                                {{  form.name }}
                            </div>
                            <div class="pa-4">
                                <v-icon
                                    v-if="edit"
                                    size="18"
                                    color="secondary"
                                    class="add_icon"
                                    @click="addField()"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </div>
                    </div>

                    <v-card-text>
                        <v-row  v-for="(field, fieldName) in form.fields" :key="fieldName">
                            <v-col ccols="12" md="6" class="client_title">
                                <v-icon v-if="edit" color="primary" size="15" :title="$t('delete')" @click="deleteField(fieldName,field)">mdi-delete-outline</v-icon>
                                {{field.label}}
                            </v-col>
                            <v-col cols="12" md="6" class="client_text"  v-if="field.type === 'datetime'">

                                <span v-if="!(editingFields.includes(field))" @click="showInput(field)">
                                    <span v-if="formData[fieldName]  && formData[fieldName]  !== 'null'">{{ formData[fieldName]  }}</span>
                                    <span v-else class="client_no_text">{{ $t('enter_text') }}</span>
                                </span>
                                <span v-else>
                                    <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                                         <datetime-picker v-model="formData[fieldName]" validator-name="deadline"
                                                          :error="!valid"
                                                          :validator-rules="field.rules"
                                                          format="24hr"
                                                          :label="field.label" :locale="lang"
                                                          :min-date-time="$moment().format('YYYY-MM-DD')"
                                                          :timezone="$auth.user().timezone" timeFormat="HH:mm"
                                                          readonly
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          @input="hideInput(field, valid, formData[fieldName], fieldName)"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></datetime-picker>
                                    </ValidationProvider>
                                </span>
                            </v-col>
                            <v-col cols="12" md="6" class="client_text"  v-if="field.type === 'date'">

                                <span v-if="!(editingFields.includes(field))" @click="showInput(field)">
                                    <span v-if="formData[fieldName]  && formData[fieldName]  !== 'null'">{{ formData[fieldName]  }}</span>
                                    <span v-else class="client_no_text">{{ $t('enter_text') }}</span>
                                </span>
                                <span v-else>
                                    <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                                         <DatePicker v-model="formData[fieldName]" validator-name="deadline"
                                                          :error="!valid"
                                                          :validator-rules="field.rules"
                                                          format="24hr"
                                                          :label="field.label" :locale="lang"
                                                          :min-date-time="$moment().format('YYYY-MM-DD')"
                                                          readonly
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          @input="hideInput(field, valid, formData[fieldName], fieldName)"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></DatePicker>
                                    </ValidationProvider>
                                </span>
                            </v-col>
                            <v-col cols="12" md="6" class="client_text"  v-if="field.type === 'string' || field.type === 'number' || field.type === 'integer'">
                                <span v-if="!(editingFields.includes(field))" @click="showInput(field)">
                                    <span v-if="formData[fieldName]  && formData[fieldName]  !== 'null'">{{ formData[fieldName]  }}</span>
                                    <span v-else class="client_no_text">{{ $t('enter_text') }}</span>
                                </span>
                                <span v-else>
                                    <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                                        <v-text-field
                                            :error="!valid"
                                            :error-messages="errors"
                                            :label="$t('enter_text')"
                                            @focusout="hideInput(field, valid, formData[fieldName], fieldName)"
                                            autofocus
                                            v-model="formData[fieldName]"
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            multiple
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </span>
                            </v-col>
                            <v-col cols="12" md="6" class="client_text"  v-if="field.type === 'boolean'">
                                <span v-if="!(editingFields.includes(field))" @click="showInput(field)">
                                    <span v-if="formData[fieldName]  && formData[fieldName]  !== 'null'">{{ getBooleanFieldLabel(formData[fieldName]) }}</span>
                                    <span v-else class="client_no_text">{{ $t('select_value') }}</span>
                                </span>
                                <span v-else>
                                    <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                                        <v-select
                                            v-model="formData[fieldName]"
                                            :items="itemYN"
                                            autofocus
                                            @focusout="hideInput(field, valid, formData[fieldName], fieldName)"
                                            :error="!valid"
                                            :error-messages="errors"
                                            item-text="name"
                                            item-value="id"
                                            :label="field.label"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </span>

                            </v-col>
                            <v-col cols="12" md="6" class="client_text"  v-if="field.type === 'select' || field.type === 'enum'">
                                <span v-if="!(editingFields.includes(field))" @click="showInput(field)">
                                    <span v-if="formData[fieldName]  && formData[fieldName]  !== 'null'">{{ getSelectFieldLabel(formData[fieldName], field.items) }}</span>
                                    <span v-else class="client_no_text">{{ $t('select_value') }}</span>
                                </span>
                                <span v-else>
                                    <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                                        <v-select
                                            v-model="formData[fieldName]"
                                            :items="field.items"
                                            autofocus
                                            @focusout="hideInput(field, valid, formData[fieldName], fieldName)"
                                            :error="!valid"
                                            :error-messages="errors"
                                            item-text="name"
                                            item-value="id"
                                            :label="field.label"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </span>

                            </v-col>
                            <v-col cols="12" md="6" class="client_text"  v-if="field.type === 'list'">
                                <span v-if="!(editingFields.includes(field))" @click="showInput(field)">
                                    <span v-if="formData[fieldName]  && formData[fieldName]  !== 'null' && (!Array.isArray(formData[fieldName]) || formData[fieldName].length > 0)">{{ getSelectFieldLabel(formData[fieldName], field.items) }}</span>
                                    <span v-else class="client_no_text">{{ $t('select_value') }}</span>
                                </span>
                                <span v-else>
                                    <ValidationProvider :ref="fieldName" :rules="field.rules" v-slot="{ errors, valid }">
                                        <v-select
                                            v-model="formData[fieldName]"
                                            :multiple="field.multiple"
                                            :items="field.items"
                                            autofocus
                                            @focusout="hideInput(field, valid, formData[fieldName], fieldName)"
                                            :error="!valid"
                                            :error-messages="errors"
                                            item-text="value"
                                            item-value="id"
                                            :label="field.label"
                                            hide-details
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                        ></v-select>
                                    </ValidationProvider>
                                </span>

                            </v-col>

                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                v-model="dialogAddField"
                class="dialog_body"
            >
                <v-card flat>


                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('add_field') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="addFieldClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                        <v-col cols="12"  class="py-0">
                            <ValidationProvider ref="field" rules="required|min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('field')}}</div>
                                <v-select
                                    v-model="field"
                                    :items="fieldItems"
                                    autofocus
                                    :error="!valid"
                                    :error-messages="errors"
                                    item-text="name"
                                    item-value="slug"
                                    :label="$t('field')"
                                    hide-details
                                    outlined
                                    solo
                                    flat
                                    dense
                                    color="primary"
                                    class="ma-0 pa-0 my-0 py-0 elevation-0  input_text"
                                ></v-select>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <ValidationProvider ref="required_field" rules="required|min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('required_field')}}</div>
                                <v-select
                                    v-model="required_field"
                                    :items="itemYN"
                                    autofocus
                                    :error="!valid"
                                    :error-messages="errors"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('required_field')"
                                    hide-details
                                    outlined
                                    solo
                                    flat
                                    dense
                                    color="primary"
                                    class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                ></v-select>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <ValidationProvider ref="multiple_field" rules="required|min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('multiple_field')}}</div>
                                <v-select
                                    v-model="multiple_field"
                                    :items="itemYN"
                                    autofocus
                                    :error="!valid"
                                    :error-messages="errors"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('multiple_field')"
                                    hide-details
                                    outlined
                                    solo
                                    flat
                                    dense
                                    color="primary"
                                    class="ma-0 pa-0 my-0 py-0 elevation-0  input_text"
                                ></v-select>
                            </ValidationProvider>
                        </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-7">
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                            class="infinity_button"
                            @click="saveField"
                        >{{ $t('add') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="addFieldClose()"
                        >{{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </ValidationObserver>
        <ValidationObserver :ref="'observer_group_' + form.id" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                persistent
                v-model="dialogUpdateGroup"
                class="dialog_body"
            >
                <v-card flat>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('edit_group') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="editGroupClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-1" v-for="(language, index) in languages" :key="language.id">
                                <div class="input_label">{{$t('name_form')}} - {{language.name}}</div>
                                <ValidationProvider :ref="language.id" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                    <v-text-field
                                        :error="!valid"
                                        :error-messages="errors"
                                        :label="$t('name_form') + ' ' + language.name"
                                        autofocus
                                        v-model="language.value"
                                        hide-details
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    ></v-text-field>
                                </ValidationProvider>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-7">
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                            class="infinity_button"
                            @click="updateGroup"
                        >{{ $t('edit') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="editGroupClose()"
                        >{{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </ValidationObserver>
    </div>
</template>

<script>

import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import DatetimePicker from '@/components/DatetimePicker.vue'
import DatePicker from "@/components/DatePicker";

export default {
    name: 'FormRight',
    components: {
        ValidationObserver,
        ValidationProvider,
        DatetimePicker,
        DatePicker
    },
    props: {
        form: Object,
        entity_type: String,
        entity_uuid: String,
        edit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            editingFields: [],
            formData: this.initializeFormData(),
            itemYN: [
                {
                    id: -1,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            dialogAddField: false,
            field:null,
            fieldItems:[],
            required_field: null,
            multiple_field: null,
            dialogUpdateGroup: false

        };
    },
    computed: {
        ...mapGetters(['listLanguageNames', 'lang']),
        languages() {
            return this.listLanguageNames
        },
    },
    methods: {
        initializeFormData() {
            let data = {};
            for (let fieldName in this.form.fields) {
                data[fieldName] = this.form.fields[fieldName].default || '';
            }
            return data;
        },
        showInput(field) {
            this.editingFields.push(field);
        },
        hideInput(field, valid, value, key) {


            if (field) {
                this.editingFields = this.editingFields.filter(item => item !== field);
            } else {
                this.editingFields = [];
            }

            this.saveFieldGroup(field, value, key)


        },
        getBooleanFieldLabel(value) {
            const option = this.itemYN.find(option => option.id === value);
            return option ? option.name : '';
        },
        getSelectFieldLabel(value, items) {
            if (Array.isArray(value)) {
                return items
                    .filter(option => value.includes(option.id))
                    .map(option => option.value)
                    .join(', ');
            } else {
                const option = items.find(option => option.id === value);
                return option ? option.name : '';
            }

        },
        addField(){
            if(this.edit === false){return}
            this.dialogAddField = true
            this.gitFields();
        },
        addFieldClose(){
            this.dialogAddField = false;
            this.required_field = null;
            this.multiple_field = null;
            this.field = null;

        },
        async gitFields(){
            this.loading = true;
            let params = {};
            await this.$http
                .get(`admin/eav/type/${this.entity_type}/property`, {
                    params: params,
                })
                .then(res => {
                    this.fieldItems = res.body.data;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_fields'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async saveField(){
            if(this.edit === false){return}

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if(this.form.id){
                formData.append('group_id',  this.form.id)
            }
            if(this.field){
                formData.append('field',  this.field)
            }
            if(this.required_field === 1){
                formData.append('required_field',  1)
            }
            if(this.multiple_field === 1){
                formData.append('multiple_field',  1)
            }
            formData.append('entity_type', this.entity_type)

            await this.$http
                .post(`admin/dynamic_form`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('field_has_been_added'))
                    if(res.body.data.new_field !== null){
                        this.form.fields = {...this.form.fields, ...res.body.data.new_field};
                    }
                    this.addFieldClose();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('field_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async deleteField(field) {
            if(this.edit === false){return}
            if (confirm(this.$t('delete_field'))) {
                let params = {};
                params.group_id = this.form.id
                this.loading = true
                await this.$http
                    .delete(`admin/dynamic_form/${field}`, {
                        params: params,
                    })
                    .then(res => {
                        const fieldIndex = Object.keys(this.form.fields).findIndex(key => key === field);
                        if (fieldIndex !== -1) {
                            let newFields = { ...this.form.fields };
                            delete newFields[field];
                            this.form.fields = newFields;
                        }
                        this.$toastr.success(this.$t('field_has_been_deleted'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('field_not_been_deleted'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async deleteForm() {
            if(this.edit === false){return}
            if (confirm(this.$t('delete_group'))) {
                let params = {};
                this.loading = true
                await this.$http
                    .delete(`admin/group/${this.form.id}`, {
                        params: params,
                    })
                    .then(res => {
                        this.$emit('delete-form', this.form);
                        this.$toastr.success(this.$t('group_has_been_deleted'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('group_not_been_deleted'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async editGroup() {
            if(this.edit === false){return}
            let params = {};
            this.loading = true
            await this.$http
                .get(`admin/group/${this.form.id}`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data) {
                        let t = res.body.data;
                        for (let i = 0; i < this.languages.length; i++) {
                            this.languages[i].value = t.name[this.languages[i].id].name;
                        }
                        this.dialogUpdateGroup = true;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_group'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async updateGroup() {
            if(this.edit === false){return}
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            for (let i = 0; i < this.languages.length; i++) {
                formData.append(`${this.languages[i].id}[name]`, this.languages[i].value)
            }
            await this.$http
                .put(`admin/group/${this.form.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res?.body?.data?.name) {
                        this.$toastr.success(this.$t('group_has_been_updated'))
                        this.form.name = res.body.data.name;
                        this.editGroupClose()
                    }
                    else {
                        this.$toastr.error(this.$t('group_has_not_been_updated'))
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('group_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        editGroupClose(){
            this.dialogUpdateGroup = false;
            for (let i = 0; i < this.languages.length; i++) {
                this.languages[i].value = null;
            }
        },
        async saveFieldGroup(field, value, key){
            if(value===''){
                return;
            }
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if(this.entity_type){
                formData.append('entity_type',  this.entity_type)
            }
            if(this.entity_uuid){
                formData.append('entity_uuid',  this.entity_uuid)
            }
            if(this.form.id){
                formData.append('group_id',  this.form.id)
            }

            formData.append(`fields[${key}]`, value);

            await this.$http
                .post(`admin/group_field`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('data_is_saved'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('data_could_not_saved'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        }





    }
};
</script>


